import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Box,
} from '@mui/material';
import { FhxTitle, FhxNavButtons } from '../common/FhxContentComponents';
import IntakeServices from '../services/IntakeServices';
import DatePicker from '../common/DateField';

const services = new IntakeServices();

function FhxDateOfBirth({
  title,
  backAllowed,
  setParentStateCB,
  id,
}) {
  const [value, setValue] = useState(null);
  const [contBtnState, setContBtnState] = useState(false);

  const handleContinueClick = () => {
    services
      .postIntake(global.mkey, id, {
        id,
        date: value,
      })
      .then((response) => {
        setValue(null);
        setParentStateCB(response.result ? response.result.data.content : null);
        if (window.top !== window.self) {
          window.parent.postMessage('Activity', '*');
        }
      }).catch((error) => {
        setParentStateCB(error?.response?.status === 410 ? 'deactivated' : null);
      });
  };

  const handleBackClick = () => {
    services
      .getPreviousIntake(global.mkey, id)
      .then((response) => {
        setParentStateCB(response.result ? response.result.data.content : null);
      })
      .catch((error) => {
        setParentStateCB(error?.response?.status === 410 ? 'deactivated' : null);
      });
  };

  return (
    <>
      <Container maxWidth="md" sx={{ py: 2 }}>
        <FhxTitle title={title} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            my: 1,
          }}
        >
          <DatePicker
            value={value}
            setValue={setValue}
            disableFuture
            setIsValid={setContBtnState}
          />
        </Box>
      </Container>
      <FhxNavButtons
        backAllowed={backAllowed}
        continueAllowed={contBtnState}
        continueButtonCallback={handleContinueClick}
        backButtonCallback={handleBackClick}
      />
    </>
  );
}

FhxDateOfBirth.propTypes = {
  title: PropTypes.string.isRequired,
  backAllowed: PropTypes.bool.isRequired,
  setParentStateCB: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};

export default FhxDateOfBirth;
