/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';

function AutocompleteInput({
  options,
  getOptionLabel,
  value,
  required,
  handleInputChange,
  handleOptionChange,
  error,
  helperText,
  ...rest
}) {
  const name = rest.name ? rest.name : 'autocomplete-input';
  return (
    <Autocomplete
      options={options}
      getOptionLabel={getOptionLabel}
      value={value}
      onChange={handleOptionChange}
      freeSolo
      onInputChange={handleInputChange}
      {...rest}
      renderInput={(params) => (
        <TextField
          {...params}
          {...rest}
          required={required}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            sx: {
              '.MuiAutocomplete-endAdornment': {
                top: '50%',
                transform: 'translateY(-50%)',
                position: 'absolute',
                right: '0',
              },
            },
          }}
         // eslint-disable-next-line
          inputProps={{
            ...params.inputProps,
            'aria-label': 'Enter answer',
            'data-testid': name,
          }}
        />
      )}
    />
  );
}

AutocompleteInput.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  required: PropTypes.bool,
  getOptionLabel: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleInputChange: PropTypes.func,
  handleOptionChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default AutocompleteInput;

AutocompleteInput.defaultProps = {
  handleInputChange: () => {},
  handleOptionChange: () => {},
  error: false,
  helperText: '',
  required: false,
};
