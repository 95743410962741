import { useTheme } from '@mui/material/styles';

const useStyles = (isHighlighted) => {
  const theme = useTheme();

  const getLabelStyles = () => ({
    backgroundColor: isHighlighted ? theme.palette.primary.main : 'transparent',
    color: isHighlighted ? 'white' : 'inherit',
  });

  return {
    getLabelStyles,
  };
};

export default useStyles;
