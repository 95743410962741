import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Container, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRollbar } from '@rollbar/react';
import FhxRadioButtonGroup from '../../FhxRadioButtonGroup';
import FhxMedicationFreeTextEntry from './FhxMedFreeTextEntryForm';
import { fetchDirectionsById, extractUniqueFrequenciesFromDirectionData } from '../medicationUtils';
import medicationShape from '../medSharedPropTypes';

function FhxMedStageFrequencyOfUse({
  currentMedication,
  handleMedicationUpdate,
  setDirectionsData,
  isFreeTextDirectionStage,
  setIsFreeTextDirectionStage,
  freeTextDirection,
  setFreeTextDirection,
}) {
  const { t } = useTranslation();

  const [frequencyOptions, setFrequencyOptions] = useState([]);
  const [selectedID, setSelectedID] = useState(null);
  const [allDirections, setAllDirections] = useState([]);
  const rollbar = useRollbar();

  // dummy option ids for "Other" and "I'm not sure" options
  const OTHER_OPTION_ID = 999999;
  const UNSURE_OPTION_ID = 1000000;

  useEffect(() => {
    // if user selected a medication that is not in DB (no ID), show the free text entry form
    if (!currentMedication.id) {
      setIsFreeTextDirectionStage(true);
      return;
    }

    const fetchAndProcessDirections = async () => {
      try {
        const data = await fetchDirectionsById(currentMedication.id);
        if (data) {
          setAllDirections(data);
          if (data.length === 0) {
            setIsFreeTextDirectionStage(true);
            return;
          }
          const { uniqueFrequencies } = extractUniqueFrequenciesFromDirectionData(data);

          // Append "as needed" to frequency options if prn is true
          const processedFrequencies = uniqueFrequencies.map((option) => ({
            ...option,
            layName: option.prn ? `${option.layName} as needed` : option.layName,
          }));

          setFrequencyOptions(processedFrequencies);
          setIsFreeTextDirectionStage(false);
        } else {
          // if there is an issue with retrieving frequencies, fallback to free text entry
          setIsFreeTextDirectionStage(true);
        }
      } catch (error) {
        rollbar.warn('Error fetching or processing directions', error);
        setIsFreeTextDirectionStage(true);
      }
    };

    fetchAndProcessDirections();
  }, [currentMedication.id, setIsFreeTextDirectionStage]);

  const handleFrequencyChange = (event) => {
    const newSelectedId = Number(event.target.value);
    setSelectedID(newSelectedId);

    if (newSelectedId === OTHER_OPTION_ID) {
      // if user selects "Other", show the free text entry form
      setIsFreeTextDirectionStage(true);
      setSelectedID(null);
    } else if (newSelectedId === UNSURE_OPTION_ID) {
      // if user selects "I'm not sure", move on to the next stage with no directions data
      handleMedicationUpdate('frequency', '');
      setDirectionsData([]);
    } else {
      const selectedFrequency = frequencyOptions.find((option) => option.id === newSelectedId);
      // store frequency in medical term for display on the narrative report
      handleMedicationUpdate('frequency', selectedFrequency ? selectedFrequency.layName : '');
      // Set isScheduledMed to false if "as needed" frequency is selected
      if (selectedFrequency?.isPrn) {
        handleMedicationUpdate('isScheduledMed', false);
      }

      // Filter the directions based on the selected frequency for use in the next component.
      const filteredDirections = allDirections.filter(
        (direction) => direction.frequency.id === newSelectedId,
      );
      setDirectionsData(filteredDirections);
      setIsFreeTextDirectionStage(false);
    }
  };

  if (isFreeTextDirectionStage) {
    return (
      <FhxMedicationFreeTextEntry
        currentMedication={currentMedication}
        setFreeTextDirection={setFreeTextDirection}
        handleMedicationUpdate={handleMedicationUpdate}
        freeTextDirection={freeTextDirection}
      />
    );
  }

  // Add "Other" and "I'm not sure" options only if there are other options available
  const frequencyOptionsWithOther = frequencyOptions.length
    ? [
      ...frequencyOptions,
      { id: OTHER_OPTION_ID, layName: t('medicationForm.other') },
      { id: UNSURE_OPTION_ID, layName: t('medicationForm.unsure') },
    ]
    : frequencyOptions;

  return (
    <Container maxWidth="md">
      <Typography variant="subtitle2" gutterBottom>
        {`${t('medStageFrequency.title')} ${currentMedication.lookupName}${t('questionMark')}`}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FhxRadioButtonGroup
            options={frequencyOptionsWithOther.map((option) => ({
              id: option.id,
              displayText: option.layName,
            }))}
            selectedID={selectedID}
            handleChoiceChange={handleFrequencyChange}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

FhxMedStageFrequencyOfUse.propTypes = {
  currentMedication: medicationShape.isRequired,
  handleMedicationUpdate: PropTypes.func.isRequired,
  setDirectionsData: PropTypes.func.isRequired,
  isFreeTextDirectionStage: PropTypes.bool.isRequired,
  setIsFreeTextDirectionStage: PropTypes.func.isRequired,
  freeTextDirection: PropTypes.string.isRequired,
  setFreeTextDirection: PropTypes.func.isRequired,
};

export default FhxMedStageFrequencyOfUse;
