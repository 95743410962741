import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FhxQuestion from './FhxQuestion';
import FhxTestDrive from './TestDrive/FhxTestDrive';

export default function FhxContent({
  initialContent, mkey, isTestDrive, setCustomLocalization, medicationFormType, language,
}) {
  const [content, setContent] = useState(initialContent);
  return (
    isTestDrive ? (
      <FhxTestDrive
        mkey={mkey}
        content={content}
        setContent={setContent}
        setCustomLocalization={setCustomLocalization}
        medicationFormType={medicationFormType}
      />
    ) : (
      <FhxQuestion
        content={content}
        setContent={setContent}
        mkey={mkey}
        setCustomLocalization={setCustomLocalization}
        medicationFormType={medicationFormType}
        language={language}
      />
    )
  );
}

FhxContent.propTypes = {
  initialContent: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }),
  mkey: PropTypes.string.isRequired,
  isTestDrive: PropTypes.bool.isRequired,
  setCustomLocalization: PropTypes.func.isRequired,
  medicationFormType: PropTypes.string,
  language: PropTypes.string.isRequired,
};

FhxContent.defaultProps = {
  initialContent: null,
  medicationFormType: '',
};
