import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as FemalePedSVG } from '../assets/female_pediatric.svg';
import { ReactComponent as FemalePedConsolidatedSVG } from '../assets/female_pediatric_consolidated.svg';
import { ReactComponent as FemalePedJointSVG } from '../assets/female_pediatric_joints.svg';
import { ReactComponent as MalePedSVG } from '../assets/male_pediatric.svg';
import { ReactComponent as MalePedConsolidatedSVG } from '../assets/male_pediatric_consolidated.svg';
import { ReactComponent as MalePedJointSVG } from '../assets/male_pediatric_joints.svg';
import { ReactComponent as MaleAdultSVG } from '../assets/male_adult.svg';
import { ReactComponent as MaleAdultConsolidatedSVG } from '../assets/male_adult_consolidated.svg';
import { ReactComponent as MaleAdultJointSVG } from '../assets/male_adult_joints.svg';
import { ReactComponent as FemaleAdultSVG } from '../assets/female_adult.svg';
import { ReactComponent as FemaleAdultConsolidatedSVG } from '../assets/female_adult_consolidated.svg';
import { ReactComponent as FemaleAdultJointSVG } from '../assets/female_adult_joints.svg';
import { Controls } from '../common/Controls';

const bodyMapToDisplaySvgMap = {
  femaleChild: FemalePedSVG,
  femaleChildJoint: FemalePedJointSVG,
  femaleChildConsolidated: FemalePedConsolidatedSVG,
  femaleAdult: FemaleAdultSVG,
  femaleAdultJoint: FemaleAdultJointSVG,
  femaleAdultConsolidated: FemaleAdultConsolidatedSVG,
  maleChild: MalePedSVG,
  maleChildJoint: MalePedJointSVG,
  maleChildConsolidated: MalePedConsolidatedSVG,
  maleAdult: MaleAdultSVG,
  maleAdultJoint: MaleAdultJointSVG,
  maleAdultConsolidated: MaleAdultConsolidatedSVG,
};

function FhxBodyMap({
  bodyMapToDisplay, options, setFilteredOptions, handleChoiceChange, multiSelect, selectedIDs,
}) {
  /*
    svgRef is a reference object created by the useRef Hook
    and is often used to directly access a DOM element.
    The .current property of this object provides access to the referenced DOM element.
  */
  const svgRef = useRef(null);
  const [isSvgRendered, setIsSvgRendered] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);

  const BodyMapSVG = bodyMapToDisplaySvgMap[bodyMapToDisplay];
  const labels = [];

  const bodyMapStyle = {
    svgPathDisabled: 'display:none',
    svgParentPathDisabled: 'display: none',
    svgPathNormal: 'display:inline;opacity:0.0;fill-opacity:0.0;stroke-opacity:0;',
    svgParentPathNormal: 'display:inline;opacity:0.0;fill-opacity:0.0;stroke-opacity:0;',
    svgPathChosen: 'display:inline;opacity:0.53;fill:#da1807;fill-opacity:0.94545;stroke-opacity:1.0;stroke:#b61331;',
  };

  const setClassNameAndStyle = (path, className) => {
    path.setAttribute('className', className);
    const style = bodyMapStyle[className];
    if (style) {
      path.setAttribute('style', style);
    }
  };

  const svgSetClassNameAndStyleForAllParentPath = (className) => {
    const paths = svgRef.current.querySelectorAll("path[id^='ps']");
    paths.forEach((path) => {
      setClassNameAndStyle(path, className);
    });
  };

  const svgSetClassNameAndStyleForAllSubPath = (className) => {
    const paths = svgRef.current.querySelectorAll("path[id^='p']:not([id^='ps']):not([className=svgPathChosen])");
    paths.forEach((path) => {
      setClassNameAndStyle(path, className);
      const label = path.getAttribute('label');
      if (!labels.includes(label)) {
        labels.push(label);
      }
    });
    const newFilteredOptions = options.filter((option) => labels.includes(option.originalText));
    setFilteredOptions(newFilteredOptions);
  };

  const deselectPreviousSection = () => {
    const chosenPaths = svgRef.current.querySelectorAll("path[className*='svgPathChosen']");
    if (chosenPaths) {
      chosenPaths.forEach((path) => {
        setClassNameAndStyle(path, 'svgPathNormal');
      });
    }
  };

  /* eslint-disable no-param-reassign */
  const selectSection = (section) => {
    const label = section.getAttribute('label');
    const bodyPartOption = options.find((option) => option.originalText === label);
    if (bodyPartOption) {
      setClassNameAndStyle(section, 'svgPathChosen');
      handleChoiceChange(null, bodyPartOption.id);
    }
  };

  const deselectSection = (section) => {
    setClassNameAndStyle(section, 'svgPathNormal');
    if (multiSelect) {
      const label = section.getAttribute('label');
      const bodyPartOption = options.find((option) => option.originalText === label);
      handleChoiceChange(null, bodyPartOption.id);
    } else {
      handleChoiceChange(null, null);
    }
  };

  const zoomOut = () => {
    const gMain = svgRef.current.querySelectorAll("g[id*='gMAIN']")[0];
    gMain.setAttribute('style', 'transform: scale(1) translate(0, 0)');
    svgSetClassNameAndStyleForAllSubPath('svgPathDisabled');
    svgSetClassNameAndStyleForAllParentPath('svgParentPathNormal');
    setIsZoomed(false);
  };

  const zoomInToParentPath = (parentPath) => {
    const dimension = svgRef.current.getAttribute('viewBox').split(' ');
    const viewBox = {
      x: dimension[0],
      y: dimension[1],
      width: dimension[2],
      height: dimension[3],
    };

    const svgBox = svgRef.current.getBoundingClientRect();
    const rectBox = parentPath.getBBox();
    const svgBoxHWRatio = svgBox.height / svgBox.width;

    let zScaleWidth = (viewBox.width / rectBox.width);
    let zScaleHeight = (viewBox.height / rectBox.height);

    if (svgBoxHWRatio > 1) {
      // narrow and tall
      zScaleHeight *= svgBoxHWRatio;
    } else {
      // wide and short
      zScaleWidth /= svgBoxHWRatio;
    }
    const zScale = ((zScaleHeight < zScaleWidth) ? zScaleHeight : zScaleWidth) * 0.9;
    const x = (rectBox.x) - (viewBox.x / zScale)
      - (viewBox.width / zScale / 2) + (rectBox.width / 2);
    const y = (rectBox.y - viewBox.y / zScale - viewBox.height / zScale / 2 + rectBox.height / 2);
    const newTransform = `scale(${zScale}) translate(${-x}px, ${-y}px)`;
    const gMain = svgRef.current.querySelectorAll("g[id*='gMAIN']")[0];
    gMain.setAttribute('style', `transform: ${newTransform}`);
    setIsZoomed(true);
  };

  const handleParentPathClick = (e) => {
    const parentPath = e.target;
    zoomInToParentPath(parentPath);

    svgSetClassNameAndStyleForAllParentPath('svgParentPathDisabled');
    svgSetClassNameAndStyleForAllSubPath('svgPathNormal');
  };

  const handleChildPathClick = (e) => {
    const isSelected = e.target.getAttribute('className') === 'svgPathChosen';
    // if selectOne deselectPrevious
    if (!multiSelect && !isSelected) {
      deselectPreviousSection();
    }
    if (!isSelected) {
      selectSection(e.target);
    } else {
      deselectSection(e.target);
    }
  };

  const svgMapsAddEventListenerToAllSubPaths = () => {
    const paths = svgRef.current.querySelectorAll("path[id^='p']:not([id^='ps']):not([className=svgPathChosen])");
    paths.forEach((path) => {
      path.addEventListener('click', handleChildPathClick);
    });
  };

  const svgMapsAddEventListenerToAllParentPath = () => {
    const paths = svgRef.current.querySelectorAll("path[id^='ps']");
    paths.forEach((path) => {
      path.addEventListener('click', handleParentPathClick);
    });
  };

  // render selected section when swapping from text to img
  const renderSelectedSection = () => {
    if (selectedIDs && selectedIDs.length > 0) {
      selectedIDs.forEach((id) => {
        const opt = options.find((option) => option.id === id);
        if (opt) {
          const path = svgRef.current.querySelectorAll(`path[label="${opt.originalText}"]`)[0];
          setClassNameAndStyle(path, 'svgPathChosen');
        }
      });
    }
  };

  useEffect(() => {
    // svgRef is a reference to the body map image created by the useRef Hook
    // When you pass svgRef to an element's ref attribute, (check BodyMapSVG element below)
    // React sets its .current property to the corresponding DOM node.
    if (!isSvgRendered) {
      // Only allow to click parent path
      svgSetClassNameAndStyleForAllParentPath('svgParentPathNormal');
      svgSetClassNameAndStyleForAllSubPath('svgPathNormal');

      // add onclick action to each path
      svgMapsAddEventListenerToAllParentPath();
      svgMapsAddEventListenerToAllSubPaths();
      setIsSvgRendered(true);
    }

    renderSelectedSection();
  }, [selectedIDs, isSvgRendered]);

  const svgContainerStyle = {
    overflow: 'hidden',
    position: 'relative',
    maxWidth: '500px',
    maxHeight: '600px',
  };

  const svgStyle = {
    width: '100%',
    height: 'auto',
  };

  return (
    <div className="svg_container" style={svgContainerStyle}>
      {isZoomed && (
        <Controls.Button
          variant="outlined"
          text="Zoom Out"
          onClick={zoomOut}
          sx={{
            width: 'auto',
            height: 'auto',
            fontSize: 'auto',
            margin: '2px 0px 5px 2px',
          }}
        />
      )}
      <BodyMapSVG
        ref={svgRef}
        style={svgStyle}
      />
    </div>
  );
}

FhxBodyMap.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      originalText: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  setFilteredOptions: PropTypes.func.isRequired,
  bodyMapToDisplay: PropTypes.oneOf(['femaleChild', 'femaleAdult', 'maleChild', 'maleAdult']).isRequired,
  handleChoiceChange: PropTypes.func.isRequired,
  multiSelect: PropTypes.bool,
  selectedIDs: PropTypes.arrayOf(PropTypes.number),
};

FhxBodyMap.defaultProps = {
  multiSelect: false,
  selectedIDs: [],
};

export default FhxBodyMap;
