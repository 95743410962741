import React from 'react';
import PropTypes from 'prop-types';
import { Container, Box, Chip } from '@mui/material';
import medicationShape from './medSharedPropTypes';

function FhxMedicationListNameView({ medications, onDeleteMedication }) {
  const renderChips = () => medications.map(({
    lookupName, strength, strengthUnit, frequency,
  }, index) => (
    <Chip
      key={`${lookupName}-${strength}-${strengthUnit}-${frequency}`}
      label={lookupName}
      onDelete={() => onDeleteMedication(index)}
      sx={{ margin: 1 }}
    />
  ));

  if (medications.length === 0) return null;

  return (
    <Container maxWidth="sm" sx={{ paddingTop: 2, paddingBottom: 2 }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {renderChips()}
      </Box>
    </Container>
  );
}

FhxMedicationListNameView.propTypes = {
  medications: PropTypes.arrayOf(medicationShape).isRequired,
  onDeleteMedication: PropTypes.func.isRequired,
};

export default FhxMedicationListNameView;
