import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Container, Box } from '@mui/material';

import { FhxTitle, FhxNavButtons, FhxTextEntry } from '../common/FhxContentComponents';
import IntakeServices from '../services/IntakeServices';
import SpeechToTextButton from '../common/SpeechToTextButton';

const services = new IntakeServices();

function FhxTextForm({
  title,
  backAllowed,
  setParentStateCB,
  id,
  inputRequired,
  helperText,
  language,
  characterLimit,
  rowCount,
  submitOnEnter,
}) {
  const [value, setValue] = useState('');
  const { t } = useTranslation();
  const [recognizingSpeech, setRecognizingSpeech] = useState(false);
  const [speechRecognizer, setSpeechRecognizer] = useState(null);
  const [continueButtonLoading, setContinueButtonLoading] = useState(false);

  const stopSpeechToTextService = () => {
    setRecognizingSpeech(false);
    if (speechRecognizer) {
      speechRecognizer.stopContinuousRecognitionAsync();
    }
  };

  const handleContinueClick = () => {
    setContinueButtonLoading(true);
    stopSpeechToTextService();
    services.postIntake(global.mkey, id, { id, text: value }).then((response) => {
      // TODO: Handle all errors and remove console.log
      setValue('');
      setParentStateCB(response.result ? response.result.data.content : null);
      if (window.top !== window.self) {
        window.parent.postMessage('Activity', '*');
      }
    }).catch((error) => {
      setParentStateCB(error?.response?.status === 410 ? 'deactivated' : null);
    });
  };

  const handleBackClick = () => {
    stopSpeechToTextService();
    services.getPreviousIntake(global.mkey, id).then((response) => {
      setParentStateCB(response.result ? response.result.data.content : null);
    }).catch((error) => {
      setParentStateCB(error?.response?.status === 410 ? 'deactivated' : null);
    });
  };

  const onEnterPress = submitOnEnter ? handleContinueClick : null;

  return (
    <>
      <Container maxWidth="md" sx={{ paddingTop: 2, paddingBottom: 2 }}>
        <FhxTitle title={title} helperText={helperText} />
        <Box
          sx={{
            component: 'span',
            my: 1,
            display: 'block',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FhxTextEntry rows={rowCount} placeholder={t('text_placeholder')} value={value} setValue={setValue} characterLimit={characterLimit} onEnterPress={onEnterPress} />
        </Box>
        {process.env.REACT_APP_ENABLE_STT === 'true'
          ? (
            <SpeechToTextButton
              setValue={setValue}
              recognizingSpeech={recognizingSpeech}
              setRecognizingSpeech={setRecognizingSpeech}
              speechRecognizer={speechRecognizer}
              setSpeechRecognizer={setSpeechRecognizer}
              language={language}
            />
          ) : null}
      </Container>
      <FhxNavButtons
        backAllowed={backAllowed}
        continueAllowed={inputRequired}
        continueButtonCallback={handleContinueClick}
        backButtonCallback={handleBackClick}
        parentLoading={continueButtonLoading}
      />
    </>
  );
}

FhxTextForm.propTypes = {
  title: PropTypes.string.isRequired,
  backAllowed: PropTypes.bool.isRequired,
  setParentStateCB: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  inputRequired: PropTypes.bool.isRequired,
  helperText: PropTypes.string,
  characterLimit: PropTypes.number,
  rowCount: PropTypes.number,
  language: PropTypes.string.isRequired,
  submitOnEnter: PropTypes.bool,
};

FhxTextForm.defaultProps = {
  helperText: '',
  characterLimit: 500,
  rowCount: 2,
  submitOnEnter: false,
};

export default FhxTextForm;
