import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, MenuItem, Typography, Container,
} from '@mui/material';
import { useRollbar } from '@rollbar/react';
import { useTranslation } from 'react-i18next';
import { Controls } from '../../../common/Controls';
import MedicalContentService from '../../../services/MedicalContentService';
import FhxCheckboxGroup from '../../FhxCheckboxGroup';
import medicationShape from '../medSharedPropTypes';

function FhxMedicationDosageFormInput({
  currentMedication,
  handleMedicationUpdate,
}) {
  const { t } = useTranslation();
  const rollbar = useRollbar();

  const [routeOptions, setRouteOptions] = useState([]);
  const [dosageForms, setDosageForms] = useState([]);
  const [showDosageForm, setShowDosageForm] = useState(false);
  const [isDosageFormKnown, setIsDosageFormKnown] = useState(true);
  const medicalContentService = new MedicalContentService();

  const fetchRoutes = async () => {
    try {
      const { result } = await medicalContentService.getAllRoutes();
      if (result && result.data) {
        setRouteOptions(result.data);
      }
    } catch (error) {
      rollbar.error('Failed to fetch routes', error.message);
    }
  };

  const fetchDosageForm = async (routeId) => {
    try {
      const { result } = await medicalContentService.getDosageFormByRouteId(routeId);
      if (result && result.data) {
        setDosageForms(result.data);
      }
    } catch (error) {
      rollbar.error('Failed to fetch dosage form', error.message);
    }
  };

  useEffect(() => {
    fetchRoutes();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    handleMedicationUpdate(name, value);

    if (name === 'route') {
      fetchDosageForm(value.id);
      setShowDosageForm(true);
    }
  };

  const handleCheckboxChange = (event) => {
    const isKnown = !event.target.checked;
    setIsDosageFormKnown(isKnown);

    if (!isKnown) {
      handleMedicationUpdate('dosageForm', { id: 0, dosageForm: '' });
    }
  };

  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>
            {`${t('medicationDosageForm.howDoYouTake')} ${currentMedication.lookupName} ${currentMedication.strength !== 0 ? currentMedication.strength : ''} ${currentMedication.strengthUnit || ''}${t('questionMark')}`}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ my: 1 }}>
          <Controls.Select
            id="route-input"
            name="route"
            value={currentMedication.route}
            onChange={handleChange}
            label={t('medicationDosageForm.methodOfAdmin')}
            fullWidth
            sx={{ bgcolor: 'inherit' }}
          >
            {routeOptions.map((route) => (
              <MenuItem key={route.id} value={route}>
                {route.description}
              </MenuItem>
            ))}
          </Controls.Select>
        </Grid>
        {showDosageForm && (
          <Grid item xs={12}>
            <Controls.Select
              id="dosage-form-input"
              name="dosageForm"
              value={currentMedication.dosageForm}
              onChange={handleChange}
              label={t('medicationDosageForm.dosageForm')}
              fullWidth
              disabled={!isDosageFormKnown}
              sx={{
                bgcolor: isDosageFormKnown ? 'inherit' : '#f5f5f5',
                mb: 1,
              }}
            >
              {dosageForms.map((form) => (
                <MenuItem key={form.id} value={form}>
                  {form.dosageForm}
                </MenuItem>
              ))}
            </Controls.Select>
            <FhxCheckboxGroup
              options={[{
                id: 0,
                displayText: t('medicationDosageForm.unsureDosageForm'),
              }]}
              selectedIDs={!isDosageFormKnown ? [0] : []}
              handleChoiceChange={handleCheckboxChange}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

FhxMedicationDosageFormInput.propTypes = {
  currentMedication: medicationShape.isRequired,
  handleMedicationUpdate: PropTypes.func.isRequired,
};

export default FhxMedicationDosageFormInput;
