import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, DialogContentText, Box, Container,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import MedicationFormSwitcher from './MedicationFormSwitcher';
import IntakeServices from '../../services/IntakeServices';
import FhxMedicationListFullDetailsView from './FhxMedicationListFullDetailsView';
import { FhxTitle, FhxNavButtons } from '../../common/FhxContentComponents';
import Modal from '../../common/Modal';
import { Controls } from '../../common/Controls';
import medicationShape from './medSharedPropTypes';

const services = new IntakeServices();

const initialFieldValues = {
  lookupName: '',
  strength: '',
  strengthUnit: '',
  quantity: 0,
  quantityUnit: '',
  dosageForm: { id: 0, dosageForm: '' },
  frequency: '',
  isScheduledMed: true,
  route: {
    id: 0, abbreviation: '', description: '', action: '',
  },
  dose: '',
  freeTextDirection: '',
  isComplete: false,
};

function FhxMedicationForm({
  title, medications: initialMedications, setParentStateCB, id, medicationFormType,
}) {
  const augmentedMeds = initialMedications.map((med) => ({
    ...initialFieldValues,
    ...med,
  }));
  const [medications, setMedications] = useState(augmentedMeds);
  const [showModal, setShowModal] = useState(false);
  const [currentMedication, setCurrentMedication] = useState(initialFieldValues);
  const [isFreeTextDirectionStage, setIsFreeTextDirectionStage] = useState(false);
  const [freeTextDirection, setFreeTextDirection] = useState('');

  const detailStages = medicationFormType === 'v2'
    ? ['frequencyOfUse', 'dose']
    : ['strength', 'dosageForm', 'quantity', 'frequencyManualEntry'];

  const [nameInputValue, setNameInputValue] = useState('');
  const [directionsData, setDirectionsData] = useState([]);
  const [formState, setFormState] = useState({
    currentMedicationIndex: 0,
    isNameStage: true,
    detailStageStep: 0,
  });
  const { t } = useTranslation();

  const getIndexOfNextMedToExplore = (meds) => meds.findIndex((med) => !med.isComplete);

  const handleAddMedicationName = () => {
    setMedications((prevMedications) => {
      const newMedications = [...prevMedications, currentMedication];
      setCurrentMedication(initialFieldValues);
      setNameInputValue('');
      return newMedications;
    });
  };

  const handleMedicationUpdate = (name, value) => new Promise((resolve) => {
    setMedications((prevMedications) => {
      const updatedMedications = [...prevMedications];

      if (name === 'setMedicationAsComplete') {
        // Mark the medication as complete.
        updatedMedications[formState.currentMedicationIndex].isComplete = true;
      } else {
        // Update the medication at the current index.
        updatedMedications[formState.currentMedicationIndex] = {
          ...updatedMedications[formState.currentMedicationIndex],
          [name]: value,
        };
      }
      resolve(updatedMedications);
      return updatedMedications;
    });
  });

  const handleDeleteMedication = (index) => {
    setMedications((prevMedications) => {
      const newMedications = prevMedications.filter((_, i) => i !== index);
      return newMedications;
    });
    setFormState({
      ...formState,
      currentMedicationIndex: Math.max(0, formState.currentMedicationIndex - 1),
      detailStageStep: 0,
    });
  };

  const getPreviousIntake = () => {
    services.getPreviousIntake(global.mkey, id)
      .then((response) => {
        setParentStateCB(response.result ? response.result.data.content : null);
      })
      .catch(() => setParentStateCB(null));
  };

  const navigateDetailStages = (meds) => {
    const nextMedIndex = getIndexOfNextMedToExplore(meds);
    // if all medications have been explored, show modal instead of moving to the next med
    if (nextMedIndex === -1) {
      setShowModal(true);
    }

    const currentMed = meds[formState.currentMedicationIndex];
    if (currentMed.isComplete || isFreeTextDirectionStage) {
      if (formState.currentMedicationIndex < meds.length - 1) {
        setFreeTextDirection('');
        setFormState((prevFormState) => ({
          ...prevFormState,
          currentMedicationIndex: prevFormState.currentMedicationIndex + 1,
          detailStageStep: 0,
        }));
      }
      return;
    }
    if (formState.detailStageStep < detailStages.length - 1) {
      setFormState((prevFormState) => ({
        ...prevFormState,
        detailStageStep: prevFormState.detailStageStep + 1,
      }));
    }
  };

  const handleBackClick = () => {
    switch (true) {
      // When in the name stage, back click will direct back to prev intake question
      case formState.isNameStage:
        getPreviousIntake();
        break;
      // If not in the first step of a detail stage, move back one step of the detail stages.
      case formState.detailStageStep > 0:
        setFormState({ ...formState, detailStageStep: formState.detailStageStep - 1 });
        break;
      // If on the first step of a detail stage, move back to the prev med.
      case formState.currentMedicationIndex > 0:
        setMedications((prevMedications) => {
          const updatedMedications = [...prevMedications];
          updatedMedications[formState.currentMedicationIndex - 1].isComplete = false;
          return updatedMedications;
        });
        setFormState({
          ...formState,
          currentMedicationIndex: formState.currentMedicationIndex - 1,
        });
        break;
      default:
        setFormState({
          isNameStage: true,
          currentMedicationIndex: 0,
          detailStageStep: 0,
        });
    }
  };

  const handleFormSubmission = () => {
    services.postIntake(global.mkey, id, { medications }).then((response) => {
      setParentStateCB(response.result ? response.result.data.content : null);
    }).catch((error) => {
      setParentStateCB(error?.response?.status === 410 ? 'deactivated' : null);
    });
    setShowModal(false);
  };

  const handleNameStage = async () => {
    const isCurrentMedicationNotEmpty = nameInputValue !== '';
    const noMeds = medications.length === 0;
    const showSimpleMedForm = medicationFormType === '' || medicationFormType === 'simple';
    const nextMedIndex = getIndexOfNextMedToExplore(medications);
    const noAdditionalMedsToAdd = nextMedIndex === -1;

    if (isCurrentMedicationNotEmpty) {
      handleAddMedicationName();
    } else if (noMeds || noAdditionalMedsToAdd) {
      setShowModal(true);
    } else if (showSimpleMedForm) {
      handleFormSubmission();
    } else {
      setFormState((prevFormState) => ({
        ...prevFormState,
        isNameStage: false,
        detailStageStep: 0,
        currentMedicationIndex: nextMedIndex,
      }));
    }
  };

  const handleContinueClick = async () => {
    if (formState.isNameStage) {
      await handleNameStage();
    } else {
      let updatedMedicationWithCompleteStatus = medications;
      if (isFreeTextDirectionStage || formState.detailStageStep === detailStages.length - 1) {
        setDirectionsData([]);
        updatedMedicationWithCompleteStatus = await handleMedicationUpdate('setMedicationAsComplete');
        setFreeTextDirection('');
      }
      // Navigate through the detail stages
      navigateDetailStages(updatedMedicationWithCompleteStatus);
    }
  };

  // user clicked add more meds on modal, reset back to name stage
  const handleAddMore = () => {
    setShowModal(false);
    setFormState((prevFormState) => ({
      ...prevFormState,
      isNameStage: true,
    }));
  };

  useEffect(() => {
    setCurrentMedication(medications[formState.currentMedicationIndex] || initialFieldValues);
  }, [formState.currentMedicationIndex, medications]);

  return (
    <>
      <Container maxWidth="md" sx={{ py: 2 }}>
        <FhxTitle title={title} />
        <Box sx={{ mt: 3 }}>
          <MedicationFormSwitcher
            medications={medications}
            handleDeleteMedication={handleDeleteMedication}
            currentMedication={medications[formState.currentMedicationIndex] || initialFieldValues}
            setCurrentMedication={setCurrentMedication}
            handleAddMedicationName={handleAddMedicationName}
            handleMedicationUpdate={handleMedicationUpdate}
            nameInputValue={nameInputValue}
            setNameInputValue={setNameInputValue}
            stage={formState.isNameStage ? 'name' : detailStages[formState.detailStageStep]}
            directionsData={directionsData}
            setDirectionsData={setDirectionsData}
            isFreeTextDirectionStage={isFreeTextDirectionStage}
            setIsFreeTextDirectionStage={setIsFreeTextDirectionStage}
            setFreeTextDirection={setFreeTextDirection}
            freeTextDirection={freeTextDirection}
          />
        </Box>
      </Container>
      <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
        <FhxNavButtons
          continueAllowed
          backAllowed
          backButtonCallback={handleBackClick}
          continueButtonCallback={handleContinueClick}
        />
        <Modal isOpen={showModal} handleClose={() => setShowModal(false)} title={t('medicationForm.submitMedicationList')}>
          <DialogContentText>{t('medicationForm.confirmMedicationSubmission')}</DialogContentText>
          <FhxMedicationListFullDetailsView
            medications={medications}
            onDeleteMedication={handleDeleteMedication}
            medicationFormType={medicationFormType}
          />
          <DialogActions>
            <Controls.Button onClick={handleAddMore} variant="outlined" color="primary" text={t('medicationForm.addMore')} />
            <Controls.Button onClick={handleFormSubmission} data-testid="confirmBtn" sx={{ ml: '16px !important' }} variant="contained" color="primary" text={t('medicationForm.confirm')} />
          </DialogActions>
        </Modal>
      </Box>
    </>
  );
}

FhxMedicationForm.propTypes = {
  title: PropTypes.string.isRequired,
  medications: PropTypes.arrayOf(medicationShape),
  setParentStateCB: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  medicationFormType: PropTypes.string,
};

FhxMedicationForm.defaultProps = {
  medicationFormType: '',
  medications: [],
};

export default FhxMedicationForm;
