import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Box, useMediaQuery, TextField, Typography, FormControlLabel, Checkbox,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import FhxRadioButtonGroup from '../../FhxRadioButtonGroup';
import medicationShape from '../medSharedPropTypes';
import useStyles from '../../../common/useStyles';

function FhxMedStageDoseInput({ currentMedication, handleMedicationUpdate, directionsData }) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:700px)');
  const [doseOptions, setDoseOptions] = useState([]);
  const [otherDose, setOtherDose] = useState('');
  const [selectedOptionID, setSelectedOptionID] = useState(null);
  const [isUnsure, setIsUnsure] = useState(false);

  // dummy option ID for "Other" option
  const OTHER_OPTION_ID = 999999;
  const UNSURE_OPTION_ID = 1000000;

  const { getLabelStyles } = useStyles(isUnsure);

  useEffect(() => {
    const formattedOptions = directionsData.map((direction) => {
      // if medication has no doseLay, use route.action ((i.e. Apply, Take) as the field name
      const displayText = direction.doseLay || direction.route.action;
      // additional safeguard to not display empty options in case both doseLay and action are empty
      if (!displayText) {
        return null;
      }
      return {
        id: direction.id,
        displayText,
      };
    }).filter((option) => option !== null);
    setDoseOptions(formattedOptions);
  }, [directionsData]);

  const handleOptionChange = (event) => {
    const newSelectedID = Number(event.target.value);
    setSelectedOptionID(newSelectedID);
    if (newSelectedID === OTHER_OPTION_ID) {
      handleMedicationUpdate('dose', otherDose);
    } else if (newSelectedID === UNSURE_OPTION_ID) {
      handleMedicationUpdate('dose', '');
    } else {
      const selectedOption = doseOptions.find((option) => option.id === newSelectedID);

      if (selectedOption) {
        handleMedicationUpdate('dose', selectedOption.displayText);
      }
    }
  };

  const handleUnsureChange = (e) => {
    const { checked } = e.target;
    setIsUnsure(checked);
    if (checked) {
      setSelectedOptionID(UNSURE_OPTION_ID);
      handleMedicationUpdate('dose', '');
    }
  };

  const handleFreeTextEntryChange = (event) => {
    const newDose = event.target.value;
    setOtherDose(newDose);
    handleMedicationUpdate('dose', newDose);
  };

  // append "Other" option to the end of the list if there are available dose options.
  // If not, we show the text field automatically
  const optionsForSelect = doseOptions.length > 0 ? [...doseOptions, { id: OTHER_OPTION_ID, displayText: t('medicationForm.other') }, { id: UNSURE_OPTION_ID, displayText: t('medicationForm.unsure') }] : doseOptions;

  return (
    <Container maxWidth="md" sx={{ paddingTop: 2, paddingBottom: 2 }}>
      <Typography variant="subtitle2" gutterBottom>
        {`${t('medicationDoseInputForm.howDoYouTake')} ${currentMedication.lookupName}${t('questionMark')}`}
      </Typography>
      <Box my={1} display="flex" justifyContent="center">
        {optionsForSelect.length >= 8 && !isMobile ? (
          <Container
            disableGutters
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0px !important',
            }}
          >
            <Box sx={{ width: '45%' }}>
              <FhxRadioButtonGroup
                options={optionsForSelect.slice(0, Math.floor(optionsForSelect.length / 2))}
                selectedID={selectedOptionID}
                handleChoiceChange={handleOptionChange}
              />
            </Box>
            <Box sx={{ width: '45%' }}>
              <FhxRadioButtonGroup
                options={optionsForSelect.slice(Math.floor(optionsForSelect.length / 2))}
                selectedID={selectedOptionID}
                handleChoiceChange={handleOptionChange}
              />
            </Box>
          </Container>
        ) : (
          <FhxRadioButtonGroup
            options={optionsForSelect}
            selectedID={selectedOptionID}
            handleChoiceChange={handleOptionChange}
          />
        )}
      </Box>
      <Box>
        {/* show text field automatically when there are no data from backend */}
        {(optionsForSelect.length === 0 || selectedOptionID === OTHER_OPTION_ID) && (
          <TextField
            value={otherDose}
            onChange={handleFreeTextEntryChange}
            label="Enter your dose"
            fullWidth
            sx={{ mt: 2 }}
          />
        )}
      </Box>
      {optionsForSelect.length === 0 && (
        <Box mt={2}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={isUnsure}
                onChange={handleUnsureChange}
              />
                    )}
            label={t('medicationForm.unsure')}
            sx={getLabelStyles()}
          />
        </Box>
      )}

    </Container>
  );
}

FhxMedStageDoseInput.propTypes = {
  currentMedication: medicationShape.isRequired,
  handleMedicationUpdate: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  directionsData: PropTypes.array,
};

FhxMedStageDoseInput.defaultProps = {
  directionsData: [],
};

export default FhxMedStageDoseInput;
