import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton, Typography, Container, Box, TextField,
} from '@mui/material';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import { useTranslation } from 'react-i18next';
import Modal from './Modal';
import { Controls } from './Controls';
import '../App.css';

export function FhxTitle(props) {
  const headingRef = useRef(null);

  const { title, helperText, isHidden } = props;
  if (!title) return null;
  const [modalOpen, setModalOpen] = useState(false);

  // Use useEffect to set focus on the h1 element when the component mounts or the title changes.
  // This improves accessibility by providing a clear starting point for screen readers,
  // indicating that new content has been loaded.
  useEffect(() => {
    if (headingRef.current) {
      headingRef.current.setAttribute('tabindex', '-1');
      headingRef.current.focus();
    }
  }, [title]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Typography
        ref={headingRef}
        data-testid="questionTitle"
        id="questionTitle"
        variant="h1"
        align="left"
        color="textPrimary"
        gutterBottom
        className={isHidden ? 'hidden' : ''}
        sx={{
          margin: '0.67em 0 !important',
          outline: 'none',
        }}
      >
        {title}
      </Typography>
      {helperText ? (
        <FhxHelperTextButton title={title} onButtonClick={() => setModalOpen(true)} />
      ) : null}
      <HelperTextModal
        title={title}
        helperText={helperText}
        modalOpen={modalOpen}
        onCloseRequest={() => setModalOpen(false)}
      />
    </Box>
  );
}

FhxTitle.propTypes = {
  title: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  isHidden: PropTypes.bool,
};

FhxTitle.defaultProps = {
  helperText: '',
  isHidden: false,
};

export function FhxHelperText({ helperText }) {
  return (
    <Box>
      <Typography
        data-testid="helperText"
        align="left"
        color="textPrimary"
        gutterBottom
      >
        {helperText}
      </Typography>
    </Box>
  );
}

FhxHelperText.propTypes = {
  helperText: PropTypes.string.isRequired,
};

export function FhxHelperTextButton({ title, onButtonClick }) {
  return (
    <IconButton
      data-testid="helperTextButton"
      color="primary"
      aria-label={`details on ${title}`}
      onClick={onButtonClick}
      sx={{
        margin: '4px',
      }}
    >
      <HelpOutlinedIcon
        sx={
          {
            height: '1.2rem',
            width: '1.2rem',
          }
        }
      />
    </IconButton>
  );
}

FhxHelperTextButton.propTypes = {
  title: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
};

export function HelperTextModal({
  title, helperText, modalOpen, onCloseRequest,
}) {
  return (
    <Modal
      isOpen={modalOpen}
      handleClose={onCloseRequest}
      title={title}
    >
      <FhxHelperText helperText={helperText} />
    </Modal>
  );
}

HelperTextModal.propTypes = {
  title: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  onCloseRequest: PropTypes.func.isRequired,
};

export function FhxNavButtons(props) {
  const {
    continueAllowed, backAllowed, backButtonCallback, continueButtonCallback, parentLoading,
  } = props;
  const [loading, setLoading] = useState(parentLoading || false);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';
  const backArrow = isRTL ? <ChevronRightRoundedIcon /> : <ChevronLeftRoundedIcon />;
  const continueArrow = isRTL ? <ChevronLeftRoundedIcon /> : <ChevronRightRoundedIcon />;

  useEffect(() => {
    setLoading(false);
  }, [continueButtonCallback]);

  useEffect(() => {
    setLoading(parentLoading);
  }, [parentLoading]);

  return (
    <Container data-testid="navButtons" maxWidth="md" sx={{ minHeight: 'auto' }}>
      <Box mt={1} mb={4} display="flex" justifyContent="space-between" alignItems="center">
        {backAllowed ? (
          <Controls.Button
            variant="outlined"
            data-testid="realBackButton"
            onClick={() => backButtonCallback()}
            text={t('back')}
            loadingPosition="start"
            startIcon={backArrow}
            fontWeight={600}
          />
        )
          : (
            <Controls.Button
              text={t('back')}
              data-testid="fakeBackButton"
              sx={{
                visibility: 'hidden',
              }}
            />
          )}
        {continueAllowed !== undefined
          ? (
            <Controls.Button
              data-testid="realContinueButton"
              text={t('continue')}
              onClick={() => {
                setLoading(true);
                continueButtonCallback();
              }}
              loading={loading}
              disabled={!continueAllowed || loading}
              endIcon={continueArrow}
              fontWeight={600}
            />
          )
          : (
            <Controls.Button
              text={t('continue')}
              data-testid="fakeContinueButton"
              sx={{
                visibility: 'hidden',
              }}
            />
          )}
      </Box>
    </Container>
  );
}

FhxNavButtons.propTypes = {
  continueAllowed: PropTypes.bool,
  backAllowed: PropTypes.bool.isRequired,
  backButtonCallback: PropTypes.func.isRequired,
  continueButtonCallback: PropTypes.func,
  parentLoading: PropTypes.bool,
};

FhxNavButtons.defaultProps = {
  continueAllowed: undefined,
  continueButtonCallback: () => { },
  parentLoading: false,
};

export function FhxTextEntry(props) {
  const {
    value, setValue, rows, placeholder, characterLimit, onEnterPress,
  } = props;

  const handleSubmit = async (event) => {
    event.preventDefault();
    await onEnterPress();
  };

  return (
    <Box sx={{
      textAlign: 'center',
      mt: '20px',
    }}
    >
      <form onSubmit={handleSubmit}>
        <TextField
          style={{
            width: '100%',
          }}
          placeholder={placeholder}
          rows={rows}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          multiline={rows > 1}
          inputProps={{
            maxLength: characterLimit,
            'data-testid': 'textEntry',
            'aria-label': 'Enter answer',
          }}
        />
      </form>
    </Box>
  );
}

FhxTextEntry.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  rows: PropTypes.number.isRequired,
  placeholder: PropTypes.string.isRequired,
  characterLimit: PropTypes.number,
  onEnterPress: PropTypes.func,
};

FhxTextEntry.defaultProps = {
  characterLimit: 500,
  onEnterPress: null,
};
