import React from 'react';
import PropTypes from 'prop-types';
import FhxMedStageNameInput from './sub-stages/FhxMedStageNameInput';
import FhxMedStageStrengthInput from './sub-stages/FhxMedStageStrengthInput';
import FhxMedicationDosageFormInput from './sub-stages/FhxMedStageDosageFormInput';
import FhxMedStageQuantityInput from './sub-stages/FhxMedStageQuantityInput';
import FhxMedStageFrequencyOfUse from './sub-stages/FhxMedStageFrequencyOfUse';
import FhxMedStageDoseInput from './sub-stages/FhxMedStageDoseInput';
import FrequencyOfUseUsingManualEntry from './sub-stages/FrequencyOfUseUsingManualEntry';

function MedicationFormSwitcher({
  medications,
  handleDeleteMedication,
  currentMedication,
  setCurrentMedication,
  handleMedicationUpdate,
  handleAddMedicationName,
  stage,
  nameInputValue,
  setNameInputValue,
  directionsData,
  setDirectionsData,
  isFreeTextDirectionStage,
  setIsFreeTextDirectionStage,
  setFreeTextDirection,
  freeTextDirection,
}) {
  let content;

  switch (stage) {
    case 'name':
      content = (
        <FhxMedStageNameInput
          medications={medications}
          handleDeleteMedication={handleDeleteMedication}
          setCurrentMedication={setCurrentMedication}
          handleMedicationUpdate={handleMedicationUpdate}
          handleAddMedicationName={handleAddMedicationName}
          nameInputValue={nameInputValue}
          setNameInputValue={setNameInputValue}
        />
      );
      break;
    case 'dose':
      content = (
        <FhxMedStageDoseInput
          currentMedication={currentMedication}
          handleMedicationUpdate={handleMedicationUpdate}
          setFreeTextDirection={setFreeTextDirection}
          directionsData={directionsData}
        />
      );
      break;
    case 'strength':
      content = (
        <FhxMedStageStrengthInput
          handleMedicationUpdate={handleMedicationUpdate}
          currentMedication={currentMedication}
        />
      );
      break;
    case 'dosageForm':
      content = (
        <FhxMedicationDosageFormInput
          currentForm={currentMedication.dosageForm}
          handleMedicationUpdate={handleMedicationUpdate}
          currentMedication={currentMedication}
        />
      );
      break;
    case 'quantity':
      content = (
        <FhxMedStageQuantityInput
          currentMedication={currentMedication}
          handleMedicationUpdate={handleMedicationUpdate}
        />
      );
      break;
    case 'frequencyOfUse':
      content = (
        <FhxMedStageFrequencyOfUse
          currentMedication={currentMedication}
          handleMedicationUpdate={handleMedicationUpdate}
          setDirectionsData={setDirectionsData}
          isFreeTextDirectionStage={isFreeTextDirectionStage}
          setIsFreeTextDirectionStage={setIsFreeTextDirectionStage}
          setFreeTextDirection={setFreeTextDirection}
          freeTextDirection={freeTextDirection}
        />
      );
      break;
    case 'frequencyManualEntry':
      content = (
        <FrequencyOfUseUsingManualEntry
          currentMedication={currentMedication}
          handleMedicationUpdate={handleMedicationUpdate}
        />
      );
      break;
    default:
      content = null;
      break;
  }

  return content;
}

const medicationShape = PropTypes.shape({
  lookupName: PropTypes.string,
  quantity: PropTypes.number,
  dosageForm: PropTypes.shape({
    id: PropTypes.number,
    dosageForm: PropTypes.string,
  }),
  frequency: PropTypes.string,
  strength: PropTypes.string,
  strengthUnit: PropTypes.string,
  isScheduledMed: PropTypes.bool,
  route: PropTypes.shape({
    id: PropTypes.number,
    abbreviation: PropTypes.string,
    description: PropTypes.string,
    action: PropTypes.string,
  }),
});

MedicationFormSwitcher.propTypes = {
  medications: PropTypes.arrayOf(medicationShape).isRequired,
  currentMedication: medicationShape.isRequired,
  handleMedicationUpdate: PropTypes.func.isRequired,
  handleAddMedicationName: PropTypes.func.isRequired,
  setCurrentMedication: PropTypes.func.isRequired,
  handleDeleteMedication: PropTypes.func.isRequired,
  stage: PropTypes.string.isRequired,
};
export default MedicationFormSwitcher;
