import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TextField, Container, Typography, Box, FormControlLabel, Checkbox,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import medicationShape from '../medSharedPropTypes';
import useStyles from '../../../common/useStyles';

function FhxMedicationFreeTextEntry({
  currentMedication,
  handleMedicationUpdate, freeTextDirection, setFreeTextDirection,
}) {
  const { t } = useTranslation();
  const [isUnsure, setIsUnsure] = useState(false);

  const { getLabelStyles } = useStyles(isUnsure);

  useEffect(() => {
    // reset unsure state on next medication
    if (isUnsure) {
      setIsUnsure(false);
    }
  }, [currentMedication.lookupName]);

  const onChange = async (e) => {
    const { value } = e.target;
    setFreeTextDirection(value);
    await handleMedicationUpdate('freeTextDirection', value);
  };

  const handleUnsureChange = async (e) => {
    const { checked } = e.target;
    setIsUnsure(checked);
    const value = checked ? '' : freeTextDirection;
    setFreeTextDirection(value);
    await handleMedicationUpdate('freeTextDirection', value);
  };

  return (
    <Container maxWidth="md">
      <Typography variant="subtitle2" gutterBottom>
        {`${t('medicationFreeTextDirection.title')}${currentMedication.lookupName}${t('questionMark')}`}
      </Typography>
      <Box mt={2}>
        <TextField
          label={t('medicationFreeTextDirection.label')}
          value={isUnsure ? '' : freeTextDirection}
          onChange={onChange}
          fullWidth
          disabled={isUnsure}
        />
      </Box>
      <Box mt={2}>
        <FormControlLabel
          control={
            <Checkbox checked={isUnsure} onChange={handleUnsureChange} />
          }
          label={t('medicationForm.unsure')}
          sx={getLabelStyles()}
        />
      </Box>
    </Container>
  );
}

FhxMedicationFreeTextEntry.propTypes = {
  currentMedication: medicationShape.isRequired,
  handleMedicationUpdate: PropTypes.func.isRequired,
  freeTextDirection: PropTypes.string.isRequired,
  setFreeTextDirection: PropTypes.func.isRequired,
};

export default FhxMedicationFreeTextEntry;
