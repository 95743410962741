import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRollbar } from '@rollbar/react';
import { Controls } from '../../../common/Controls';
import MedicalContentService from '../../../services/MedicalContentService';
import { MIN_TEXT_FIELD_WIDTH } from '../../../common/Input';
import FhxMedicationListNameView from '../FhxMedicationListNameView';
import medicationShape from '../medSharedPropTypes';

const initialFieldValues = {
  id: null,
  lookupName: '',
  strength: '',
  strengthUnit: '',
  quantity: 0,
  quantityUnit: '',
  dosageForm: { id: 0, dosageForm: '' },
  frequency: '',
  isScheduledMed: true,
  route: {
    id: 0,
    abbreviation: '',
    description: '',
    action: '',
  },
};

function FhxMedStageNameInput({
  setCurrentMedication,
  handleAddMedicationName,
  handleDeleteMedication,
  medications,
  nameInputValue,
  setNameInputValue,
}) {
  const { t } = useTranslation();
  const rollbar = useRollbar();
  const [nameList, setNameList] = useState([]);
  const [listOpen, setListOpen] = useState(false);
  const [inputError, setInputError] = useState('');

  const fetchMedications = async () => {
    const medicalContentService = new MedicalContentService();
    const response = await medicalContentService.getAllLookupNames();
    if (response.result) {
      setNameList(response.result.data);
    } else {
      rollbar.error('Failed to fetch medications', response.error);
    }
  };

  useEffect(() => {
    fetchMedications();
  }, []);

  const handleChange = (event, newInputValue) => {
    setNameInputValue(newInputValue);

    // Find the medication object that matches the input value and store the medication ID
    const selectedMedication = nameList.find(
      (medication) => medication.lookupName.toLowerCase() === newInputValue.toLowerCase(),
    );

    setCurrentMedication({
      ...initialFieldValues,
      lookupName: newInputValue,
      id: selectedMedication?.medicationId,
    });

    // Show name options only if input length is over 2 characters
    setListOpen(newInputValue.length >= 2);
  };

  const onAddMedication = () => {
    if (nameInputValue.trim() !== '') {
      handleAddMedicationName();
      setNameInputValue('');
      setInputError('');
      setListOpen(false);
    } else {
      setInputError(t('medicationForm.addMedicationNameErrorLabel'));
    }
  };

  return (
    <Container maxWidth="md" sx={{ padding: '0 !important' }}>
      <FhxMedicationListNameView
        medications={medications}
        onDeleteMedication={handleDeleteMedication}
      />
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 1 }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={5}
          minWidth={MIN_TEXT_FIELD_WIDTH}
        >
          <Controls.AutocompleteInput
            options={nameList
              .filter((option) => option.lookupName
                .toLowerCase()
                .startsWith(nameInputValue.toLowerCase()))
              .map((option) => option.lookupName)}
            getOptionLabel={(option) => option}
            value={nameInputValue}
            handleInputChange={handleChange}
            open={listOpen}
            onClose={() => setListOpen(false)}
            error={!!inputError}
            helperText={inputError}
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={10}
          md={2.5}
          lg={2.5}
          xl={2.5}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Controls.Button
            data-testid="addMedicationButton"
            onClick={onAddMedication}
            size="large"
            color="primary"
            variant="outlined"
            fullWidth
            sx={{
              justifyContent: 'center',
            }}
            text={t('medicationForm.addMedication')}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

FhxMedStageNameInput.propTypes = {
  medications: PropTypes.arrayOf(medicationShape).isRequired,
  setCurrentMedication: PropTypes.func.isRequired,
  handleAddMedicationName: PropTypes.func.isRequired,
  handleDeleteMedication: PropTypes.func.isRequired,
  setNameInputValue: PropTypes.func.isRequired,
  nameInputValue: PropTypes.string.isRequired,
};

export default FhxMedStageNameInput;
