import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Box, List, ListItem, ListItemText, IconButton, Divider,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import medicationShape from './medSharedPropTypes';

function FhxMedicationListFullDetailsView({ medications, onDeleteMedication, medicationFormType }) {
  const { t } = useTranslation();
  const renderMedications = () => medications.map(({
    lookupName, quantity, quantityUnit, frequency,
    dosageForm, strength, strengthUnit, isScheduledMed, route,
    dose, freeTextDirection,
  }, index) => {
    const quantityWithUnitMessage = quantity !== 0 ? `${quantity} ${quantityUnit}` : '';
    const strengthWithUnitMessage = strength ? `${strength} ${strengthUnit}` : '';
    const scheduleMessage = isScheduledMed ? '' : t('medicationList.asNeeded');

    const medDirection = medicationFormType === 'v2'
      ? `${dose ?? ''} ${freeTextDirection ?? ''} ${frequency} `
      : `${route.action} ${quantityWithUnitMessage} ${route.description} ${frequency} ${scheduleMessage}`;

    return (
      <React.Fragment key={`${lookupName}-${strength}-${strengthUnit}-${dose}`}>
        <ListItem data-testid={`${lookupName}-${strength}-${strengthUnit}`} sx={{ px: 0 }}>
          <ListItemText
            sx={{ wordWrap: 'break-word' }}
            primary={`${lookupName} ${strengthWithUnitMessage} ${dosageForm && dosageForm.dosageForm ? `(${dosageForm.dosageForm})` : ''}`}
            secondary={medDirection}
          />
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => onDeleteMedication(index)}
          >
            <DeleteIcon />
          </IconButton>
        </ListItem>
        <Divider component="li" />
      </React.Fragment>
    );
  });

  if (medications.length === 0) return null;

  return (
    <Container maxWidth="sm" sx={{ paddingTop: 2, paddingBottom: 2 }}>
      <Box component="span" m={1}>
        <List>{renderMedications()}</List>
      </Box>
    </Container>
  );
}

FhxMedicationListFullDetailsView.propTypes = {
  medications: PropTypes.arrayOf(medicationShape).isRequired,
  onDeleteMedication: PropTypes.func.isRequired,
  medicationFormType: PropTypes.string,
};

FhxMedicationListFullDetailsView.defaultProps = {
  medicationFormType: '',
};

export default FhxMedicationListFullDetailsView;
