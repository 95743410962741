import axios from 'axios';
import axiosRetry from 'axios-retry';

const formatResponse = (response) => {
  if (response.status === 200) return { result: response };
  return { result: undefined, error: response.data };
};
export default class IntakeServices {
  constructor() {
    this.client = null;
    this.api_url = process.env.REACT_APP_ENDPOINT;
  }

  init() {
    this.api_token = null; // TODO: get access token from cookie
    const headers = {
      Accept: 'application/json',
    };
    if (this.api_token) {
      headers.Authorization = `Bearer ${this.api_token}`;
    }
    this.client = axios.create({ baseURL: `${this.api_url}/v1/public`, timeout: 5000, headers });
    // Setup axios-retry to always retry and log each retry attempt
    axiosRetry(this.client, {
      retries: 4,
      retryCondition: (error) => {
        const status = error?.response?.status;

        if (status && status >= 400 && status < 500) {
          return false;
        }
        return axiosRetry.isNetworkOrIdempotentRequestError(error);
      },
      retryDelay: axiosRetry.linearDelay(100),
    });
    return this.client;
  }

  async getIntake(mkey) {
    const result = await this.init().get(`/${mkey}`);
    return formatResponse(result);
  }

  async createPatientInitiatedIntake(siteId, identifiableData, captchaToken) {
    const result = await this.init().post('/createPtInit', { siteId, identifiableData, captchaToken });
    return formatResponse(result);
  }

  async postIntake(mkey, contentId, data) {
    if (typeof contentId !== 'number') return { error: 'ContentId is required and must be a number.' };

    let requestData;
    if (data instanceof FormData) {
      data.append('contentId', contentId);
      requestData = data;
    } else {
      requestData = { contentId, data };
    }

    const result = await this.init().post(`/${mkey}/answer`, requestData);

    if (window.top !== window.self && result.data) {
      switch (result.data.intakeStatus) {
        case 'completed':
          if (result.data.content.type === 'feedback') {
            window.parent.postMessage('Completed', '*');
          }
          break;
        case 'consentDeclined':
          if (result.data.content.type === 'text') {
            window.parent.postMessage('Activity', '*');
            window.parent.postMessage('Completed', '*'); // TODO: specify host
            setTimeout(() => {
              window.parent.postMessage('IntakeEnded', '*');
            }, 5000);
          }
          break;
        default:
          break;
      }
    }
    return formatResponse(result);
  }

  async updateLocalization(mkey, contentId, data) {
    if (typeof contentId !== 'number') return { error: 'ContentId is required and must be a number.' };
    const { options, selectedLocalization } = data;
    const body = {
      contentId,
      data: { options },
      selectedLocalization,
    };
    const result = await this.init().post(`/${mkey}/localizationAndAnswer`, body);
    return formatResponse(result);
  }

  async getPreviousIntake(mkey, contentId) {
    const result = await this.init().post(`/${mkey}/back`, { questionNumber: contentId });
    return formatResponse(result);
  }

  async getReportForTestDrive(mkey) {
    const result = await this.init().get(`/${mkey}/testDriveReview`);
    return formatResponse(result);
  }

  async submitReportComment(mkey, questionNumber, report, comment) {
    await this.init().post(
      `/${mkey}/testDriveReview/comment`,
      { questionNumberWhenCommentOccurred: questionNumber, reportDisplayed: report, comment },
    );
  }

  async submitCommentOnQuestion(mkey, questionNumber, comment) {
    await this.init().post(
      `/${mkey}/commentOnQuestion`,
      { questionNumberWhenCommentOccurred: questionNumber, comment },
    );
  }

  async restartIntake(mkey) {
    const result = await this.init().post(
      `/${mkey}/restart`,
    );
    return formatResponse(result);
  }
}
