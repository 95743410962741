import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Box,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FhxTitle, FhxNavButtons } from '../common/FhxContentComponents';
import IntakeServices from '../services/IntakeServices';
import FhxBodyMap from './FhxBodyMap';
import FhxBodyMapControlToggle from './FhxBodyMapControlToggle';
import FhxCheckboxGroup from './FhxCheckboxGroup';

const services = new IntakeServices();

function FhxSelectAll({
  title, backAllowed, inputRequired, options,
  setParentStateCB, id, helperText, isTestDrive, type, bodyMapToDisplay,
}) {
  const [contBtnState, setContBtnState] = useState(!inputRequired);
  const maxColLength = 6;
  const isMobile = isTestDrive || useMediaQuery('(max-width:700px)');
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [isImageMode, setIsImageMode] = useState(true);
  const [filteredOptions, setFilteredOptions] = useState(options);

  const handleContinueClick = () => {
    const selections = selectedIDs.map(
      (selectedID) => options.find((option) => option.id === selectedID),
    );
    services.postIntake(global.mkey, id, { options: selections }).then((response) => {
      setParentStateCB(response.result ? response.result.data.content : null);
      if (window.top !== window.self) {
        window.parent.postMessage('Activity', '*');
      }
    }).catch((error) => {
      setParentStateCB(error?.response?.status === 410 ? 'deactivated' : null);
    });
  };
  const handleBackClick = () => {
    services.getPreviousIntake(global.mkey, id).then((response) => {
      setParentStateCB(response.result ? response.result.data.content : null);
    }).catch((error) => {
      setParentStateCB(error?.response?.status === 410 ? 'deactivated' : null);
    });
  };

  useEffect(() => {
    setSelectedIDs([]);
    if (type !== 'selectAllBodyParts') {
      setFilteredOptions(options);
    }
    setContBtnState(!inputRequired);
    window.scrollTo(0, 0);
  }, [title, options, inputRequired]);

  /**
 * This function handles the selection and deselection of options.
 *
 * It checks the following conditions:
 * 1. If the new option has the property `deselectAll` set to true, it will
 * clear all other selections and only select this option.
 * 2. If the "deselectAll" option is currently selected and a different option
 * is chosen, it will deselect the "deselectAll" option and select the new option.
 * 3. If the new option is already selected, it will be deselected.
 * 4. If none of the above conditions are met, it adds the new option to the selection.
 *
 * After handling the selection/deselection, it updates the state of the selected options.
 * Then, it checks if at least one option is selected. If yes, it enables the continue
 * button (`setContBtnState(true)`), otherwise it disables it (`setContBtnState(false)`).
 */
  const handleChoiceChange = (event, newSelectedId) => {
    setSelectedIDs((prevSelectedIds) => {
      let arr = [...prevSelectedIds];
      const option = options.find((e) => e.id === newSelectedId);

      const deselectOtherAnswersOption = options.find((e) => e.deselectOtherAnswers === true);

      if (option.deselectOtherAnswers === true) {
        arr = [newSelectedId];
      } else if (deselectOtherAnswersOption && arr.includes(deselectOtherAnswersOption.id)) {
        arr = [newSelectedId];
      } else if (arr.includes(newSelectedId)) {
        arr = arr.filter((e) => e !== newSelectedId);
      } else {
        arr.push(newSelectedId);
      }
      if (arr.length >= 1) {
        setContBtnState(true);
      } else {
        setContBtnState(false);
      }

      return arr;
    });
  };

  const handleViewModeToggle = () => {
    setIsImageMode(!isImageMode);
  };

  const renderBodyPartOptions = () => (
    <Box component="span" m={1} display="flex" justifyContent="center">
      <FhxBodyMap
        options={options}
        bodyMapToDisplay={bodyMapToDisplay}
        handleChoiceChange={handleChoiceChange}
        selectedIDs={selectedIDs}
        setFilteredOptions={setFilteredOptions}
        multiSelect
      />
    </Box>
  );

  const renderToggleButtonOptions = () => {
    // Render toggle buttons in two columns for larger screens or one column for smaller screens
    if (options.length >= maxColLength && !isMobile) {
      return (
        <Container
          disableGutters
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0px !important',
            mt: 1,
          }}
        >
          <Box
            sx={{
              width: '45%',
            }}
          >
            <FhxCheckboxGroup
              options={filteredOptions.slice(0, Math.floor(filteredOptions.length / 2))}
              selectedIDs={selectedIDs}
              handleChoiceChange={handleChoiceChange}
            />
          </Box>
          <Box
            sx={{
              width: '45%',
            }}
          >
            <FhxCheckboxGroup
              options={filteredOptions.slice(Math.floor(filteredOptions.length / 2))}
              selectedIDs={selectedIDs}
              handleChoiceChange={handleChoiceChange}
            />
          </Box>
        </Container>

      );
    }
    // For smaller screens or fewer options, use a single column
    return (
      <FhxCheckboxGroup
        options={filteredOptions}
        selectedIDs={selectedIDs}
        handleChoiceChange={handleChoiceChange}
      />
    );
  };

  const renderOptions = () => {
    if (type === 'selectAllBodyParts') {
      // allow user to switch between body part image mode and text button selection mode
      return isImageMode ? renderBodyPartOptions() : renderToggleButtonOptions();
    }
    return renderToggleButtonOptions();
  };

  return (
    <>
      <Container maxWidth="md" sx={{ paddingTop: 2, paddingBottom: 2 }}>
        <FhxTitle title={title} helperText={helperText} />
        {type === 'selectAllBodyParts' && (
          <FhxBodyMapControlToggle
            isImageMode={isImageMode}
            handleViewModeToggle={handleViewModeToggle}
          />
        )}
        {renderOptions()}
      </Container>
      <FhxNavButtons
        backAllowed={backAllowed}
        continueAllowed={contBtnState}
        continueButtonCallback={handleContinueClick}
        backButtonCallback={handleBackClick}
      />
    </>
  );
}

FhxSelectAll.propTypes = {
  title: PropTypes.string.isRequired,
  backAllowed: PropTypes.bool.isRequired,
  inputRequired: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      displayText: PropTypes.string.isRequired,
      deselectOtherAnswers: PropTypes.bool,
    }),
  ).isRequired,
  setParentStateCB: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  helperText: PropTypes.string,
  isTestDrive: PropTypes.bool,
  type: PropTypes.string.isRequired,
  bodyMapToDisplay: PropTypes.oneOf(['femaleChild', 'femaleAdult', 'maleChild', 'maleAdult', undefined]),
};

FhxSelectAll.defaultProps = {
  helperText: '',
  isTestDrive: false,
  bodyMapToDisplay: undefined,
};

export default FhxSelectAll;
